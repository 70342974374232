import React from 'react'

function Header() {
    return (
        <>

            <div id="mySidenav" className="sidenav">

                <div className="zman">
                    <a className="langon" href="/so/"> كوردی </a>
                    <a className="" href="/ar/"> عربي </a>
                    <a className="" href="/fa/"> فارسى </a>
                    <br />
                    <a className="" href="/kr/"> Kurdî </a>
                    <a className="" href="/tr/"> Türkçe </a>
                    <a className="" href="/en/"> English </a>

                </div>

                <br />
                <div className="peshgr">
                    <form action="/so/garan/">
                        <input type="text" maxLength="200" placeholder="گه‌ڕان" name="search" />
                    </form>

                    <span className="dropdown-btn">
                        <a>
                            هه‌واڵ
                            <i className="fa fa-caret-down"></i>
                        </a>

                    </span>

                    <div className="dropdown-container">
                        <a href="/so/bash/115/0"> كوردستان </a>
                        <a href="/so/bash/116/0"> عێراق </a>
                        <a href="/so/bash/117/0"> ڕۆژهه‌ڵاتی ناوه‌ڕاست </a>
                        <a href="/so/bash/118/0"> تاراوگه‌ </a>
                        <a href="/so/bash/119/0"> جیهان‌ </a>

                    </div>

                    <a href="/so/bash/120/0"> وتــار </a>
                    <a href="/so/bash/121/0"> ڕاپۆرت </a>
                    <a href="/so/bash/122/0"> چاوپێكه‌وتن </a>
                    <a href="/so/bash/123/0"> ئابووری </a>

                    <span className="dropdown-btn">
                        <a>
                            مه‌ڵتیمیدیا
                            <i className="fa fa-caret-down"></i>
                        </a>

                    </span>

                    <div className="dropdown-container">
                        <a href="/so/bash/328/0"> ڤیدیۆ </a>
                        <a href="/so/bash/329/0"> كاریكاتێر </a>
                        <a href="/so/bash/330/0"> ئینفۆگرافیك </a>
                    </div>

                    <a href="/so/bash/130/0"> لایف ستایل </a>
                    <a href="/so/bash/142/0">  كولتوور و هونه‌ر </a>




                </div>

            </div>


            <div className="mobile" style={{ padding: "6px 0px 6px 0px" }}>
                <div className="one"> <a href="javascript:void(0)" style={{ fontSize: "24px", padding: "0px 10px", fontWeight: "bold", color: "#ddd" }}> <i className="fa fa-bars"></i> </a> </div>

                <div className="two">
                    <a href="/so/" className="right" rel="alternate">
                        <img className="logo1" src="/images/logo-white.svg" alt="" />
                    </a></div>

                <div className="three"><button className="openBtn fa fa-search" ></button></div>
            </div>





        </>
    )
}

export default Header