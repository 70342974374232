import React from 'react'

function Aside() {
    return (
        <>
            <section className='xat'>
                <div className="chewa">
                    <div className='rubar barzunzm'>


                        <div className="layrast">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/basnews" target="_blank"><i className="fa fa-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/basnews2013/" target="_blank"><i className="fa fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/basnewss/" target="_blank"><i className="fa fa-instagram"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/@basnews8678" target="_blank"><i className="fa fa-youtube"></i></a>
                                </li>
                                <li>
                                    <a href="https://t.me/basnewsmedia" target="_blank"><i className="fa fa-telegram"></i></a>
                                </li>
                            </ul>
                        </div>



                        <div className="laynawand">
                            <div className="logo1">
                                <a href="/so/" className="right" rel="alternate">
                                    <img className="logo1" id="logo1" src="https://www.basnews.com/images/logo-white.svg" alt="" />
                                </a>
                            </div>

                        </div>



                        <div className="laychap">
                            <a className="active" href="/so/">كوردى </a>
                            <a className="" href="/ar/"> عربى </a>
                            <a className="" href="/fa/"> فارسى </a>
                            <a className="" href="/en/">English</a>
                            <a className="" href="/tr/">Türkçe</a>
                            <a className="" href="/kr/">Kurdî</a>
                        </div>

                    </div>
                </div>
            </section>

            <div id="navbar">
                <div className='rubar chewa'>





                    <div className="sarawa_nawarast">
                        <div id="menu" className="menu">

                            <a href="/so/"> سه‌ره‌تا </a>


                            <div className="dropdown">
                                <span className="sub"><a href="/so/bash/114/0">
                                    هه‌واڵه‌كان
                                    &nbsp; <i className="fa fa-caret-down"></i>
                                </a>
                                </span>
                                <div className="dropdown-content">
                                    <a href="/so/bash/115/0"> كوردستان </a>
                                    <a href="/so/bash/116/0"> عێراق </a>
                                    <a href="/so/bash/117/0"> ڕۆژهه‌ڵاتی ناوه‌ڕاست </a>
                                    <a href="/so/bash/118/0"> تاراوگه‌ </a>
                                    <a href="/so/bash/119/0"> جیهان‌ </a>

                                </div>
                            </div>

                            <a href="/so/bash/120/0"> وتــار </a>
                            <a href="/so/bash/121/0"> ڕاپۆرت </a>
                            <a href="/so/bash/122/0"> چاوپێكه‌وتن </a>
                            <a href="/so/bash/123/0"> ئابووری </a>


                            <div className="dropdown">
                                <span className="sub"><a href="/so/bash/126/0">
                                    مه‌ڵتیمیدیا
                                    &nbsp; <i className="fa fa-caret-down"></i>
                                </a>
                                </span>
                                <div className="dropdown-content">
                                    <a href="/so/bash/328/0"> ڤیدیۆ </a>
                                    <a href="/so/bash/329/0"> كاریكاتێر </a>
                                    <a href="/so/bash/330/0"> ئینفۆگرافیك </a>
                                </div>
                            </div>


                            <a href="/so/bash/130/0"> لایف ستایل </a>
                            <a href="/so/bash/142/0"> كولتوور و هونه‌ر </a>
                        </div>



                    </div>


                    <div id="garan" className="sarawa_chap">
                        <button style={{ border: "none", background: "#136aa1", color: "#fff", width: "35px", height: "30px", borderRadius: "4px", fontSize: "20px" }} className="openBtn fa fa-search"></button>
                    </div>


                </div>
            </div>



            <div id="myOverlay" className="overlay">
                <span className="closebtn" title="Close Overlay"> x </span>
                <div className="overlay-content">
                    <form action="/so/garan/" >
                        <input type="text" autoComplete="off" placeholder="گه‌ڕان.." name="search" />
                        <button type="submit"><i className="fa fa-search"></i></button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Aside