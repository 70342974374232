import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Template5 from './Template.5';
import Template6 from './template6';
// import Template7 from './Template7';
// import Template8 from './Template8';

function App() {
  return (
    <>
      <Router>

        <Routes>

          {/* <Route exact path="/" Component={MainColumn} /> */}
          {/* <Route exact path="/hawal-3432" Component={Template} />
          <Route exact path="/hawal-7584" Component={Template2} />
          <Route exact path="/hawal-9812" Component={Template3} />
          <Route exact path="/hawal-6599" Component={Template4} /> */}
          {/* <Route exact path="/hawal-18523" Component={Template6} /> */}
          {/* <Route exact path="/hawal-28523" Component={Template6} /> */}
          <Route exact path="/hawal-2117724" Component={Template6} />
          {/* <Route exact path="/hawal-1310723" Component={Template5} /> */}
          {/* <Route exact path="/hawal-2260723" Component={Template6} /> */}
          {/* <Route exact path="/hawal-2250723" Component={Template7} /> */}
          {/* <Route exact path="/hawal-2170823" Component={Template8} /> */}
          {/* <Route exact path="/hawal/7583" Component={Template} /> */}

        </Routes>
      </Router>
      {/* <MainColumn /> */}
    </>
  );
}

export default App;