import React from 'react'

function Aside2() {
    return (
        <>

            <div className="block" >


                <div className="etar jegr">

                    <h3 className="hel">
                        <i className="fa fa-square-o" style={{ fontSize: "25px", color: "#ffab00", position: "relative", top: "5px" }}>
                            <i className="fa fa-square" style={{ fontSize: "10px", color: "#ffab00", position: "absolute", margin: "6px -14px 0px 0px" }} ></i> </i>
                        <span><a style={{ color: "#ffab00", fontSize: "14px" }} href="/so/bash/120/0"> وتـــار </a></span>
                    </h3>

                    <div className="grng">





                        <div className="sarder4">

                            <div className="xawanaka">
                                <span >
                                    <a style={{ color: "#666" }} href="/so/nusar/818/0">
                                        کارزان گلی            </a>
                                </span>
                            </div>


                            <img src="https://www.basnews.com/media/k2/items/cache/721b9746cfcefe75dace83e0dee17dde_M.jpg?1686210231" />

                            <a href="/so/babat/810676">
                                <h4>بەغدام بە چاویلكەی خۆم بینی، نەك چاویلكەی هەندێك لە پەرلەمانتارە كوردەكان        </h4>
                            </a>
                        </div>






                        <div className="sarder4">

                            <div className="xawanaka">
                                <span >
                                    <a style={{ color: "#666" }} href="/so/nusar/1985/0">
                                        هاوبیر            </a>
                                </span>
                            </div>


                            <img src="https://www.basnews.com/media/k2/items/cache/a18f33012f04745061c0c381d2657aac_M.jpg?1686145442" />

                            <a href="/so/babat/810587">
                                <h4>هـاوارێـک بـۆ کـورد        </h4>
                            </a>
                        </div>






                        <div className="sarder4">

                            <div className="xawanaka">
                                <span >
                                    <a style={{ color: "#666" }} href="/so/nusar/1759/0">
                                        جەمال عەبدوڵا            </a>
                                </span>
                            </div>


                            <img src="https://www.basnews.com/media/k2/items/cache/b2f3f4706b4d4d59895067ea35c62c1b_M.jpg?-62169984000" />

                            <a href="/so/babat/810581">
                                <h4>حكوومەت ماوە بۆیە تۆیش ھەیت!        </h4>
                            </a>
                        </div>






                        <div className="sarder4">

                            <div className="xawanaka">
                                <span >
                                    <a style={{ color: "#666" }} href="/so/nusar/1992/0">
                                        دكتۆر كەمال كەركووكی            </a>
                                </span>
                            </div>


                            <img src="https://www.basnews.com/media/k2/items/cache/904626e2fed78e35d6dfb889384879a1_M.jpg?1686130382" />

                            <a href="/so/babat/810531">
                                <h4>گرینگترین پێشێلکارییە یاساییەکان لە هەموارەکانی یاسای بودجە (ماددەکانی 13 و 14)        </h4>
                            </a>
                        </div>






                        <div className="sarder4">

                            <div className="xawanaka">
                                <span >
                                    <a style={{ color: "#666" }} href="/so/nusar/1592/0">
                                        هەرمن ئەحمەد            </a>
                                </span>
                            </div>


                            <img src="https://www.basnews.com/media/k2/items/cache/68a6bdb0d62ca32105cf1a11c93bc33d_M.jpg?1686070151" />

                            <a href="/so/babat/810452">
                                <h4>دۆخی یەكێتی و عەدالەتی خودای گەورە</h4>
                            </a>
                        </div>


                    </div>

                </div>
            </div>

        </>
    )
}

export default Aside2