import React from 'react'

function Footer() {
    return (
        <>


            <section className="seyam">
                <div className="chewa">

                    <div className="dwainhawal">



                        <h3 className="hel">
                            <i className="fa fa-square-o" style={{ fontSize: "30px", color: "#136aa1", position: "relative", top: "5px" }}>
                                <i className="fa fa-square" style={{ fontSize: "14px", color: "#136aa1", position: "absolute", margin: "7px -18px 0px 0px" }} ></i> </i>
                            <span className="xabarr"><a href="/so/bash/114/0"> دوایین هه‌واڵه‌كان </a></span>
                        </h3>

                        <div className="dwain">


                            <a className="hawal2" href="/so/babat/810673">
                                <img src="https://www.basnews.com/media/k2/items/cache/58ee3b4ba25880ae322aa57be10623ce_M.jpg?-62169984000" />
                                <span className="draw">1</span>
                                <div className="der9" >
                                    <h4>سێمێل.. بەهۆی ڕووداوێکی هاتووچۆ سێ کەس بوونە قوربانی        </h4>
                                </div>
                            </a>
                            <a className="hawal2" href="/so/babat/810672">
                                <img src="https://www.basnews.com/media/k2/items/cache/b3c4225ad5e95946b17ea98330b9ab3e_M.jpg?-62169984000" />
                                <span className="draw">2</span>
                                <div className="der9" >
                                    <h4>لە كەركووك منداڵێكی تازە لەدایكبوو بە فڕێدراوی دۆزرایەوە        </h4>
                                </div>
                            </a>
                            <a className="hawal2" href="/so/babat/810666">
                                <img src="https://www.basnews.com/media/k2/items/cache/5ae0f46c83ddbd14a4d08fb558be3ab5_M.jpg?-62169984000" />
                                <span className="draw">3</span>
                                <div className="der9" >
                                    <h4>حكوومەتی هەرێمی كوردستان كارەبای نیشتمانی دەگەیەنێتە 23 گوندی سنووری ئیدارەی گەرمیان        </h4>
                                </div>
                            </a>
                            <a className="hawal2" href="/so/babat/810665">
                                <img src="https://www.basnews.com/media/k2/items/cache/0dfe9770e915fa30ee4dc3dc5d0f3e1b_M.jpg?1686208710" />
                                <span className="draw">4</span>
                                <div className="der9" >
                                    <h4>مەکتەبی سیاسیی پارتی کۆ دەبێتەوە        </h4>
                                </div>
                            </a>
                            <a className="hawal2" href="/so/babat/810662">
                                <img src="https://www.basnews.com/media/k2/items/cache/177c4681cd7b4e75983dede68f03a845_M.jpg?-62169984000" />
                                <span className="draw">5</span>
                                <div className="der9" >
                                    <h4>کۆیە.. بودجە بۆ تەواوکردنی پرۆژەکان دابین دەکرێت        </h4>
                                </div>
                            </a>
                            <a className="hawal2" href="/so/babat/810657">
                                <img src="https://www.basnews.com/media/k2/items/cache/a0991d20c00a5beab6a286c17ab6660a_M.jpg?1686206472" />
                                <span className="draw">6</span>
                                <div className="der9" >
                                    <h4>وەزارەتی دارایی نووسراوێک دەکات و باسی پاشەکەوتی مووچەشی تێدایە        </h4>
                                </div>
                            </a>
                            <a className="hawal2" href="/so/babat/810646">
                                <img src="https://www.basnews.com/media/k2/items/cache/4cc18cb3c1ad9dd05dc916fbd8979f75_M.jpg?1686172284" />
                                <span className="draw">7</span>
                                <div className="der9" >
                                    <h4>چوارچێوەی هاوئاهەنگی: لە كۆبوونەوەی ئەمڕۆی پەرلەماندا پرۆژەیاسای بودجە پەسەند دەكرێ        </h4>
                                </div>
                            </a>
                            <a className="hawal2" href="/so/babat/810630">
                                <img src="https://www.basnews.com/media/k2/items/cache/05de59a64a86236dc8a93310ec706088_M.jpg?1686168236" />
                                <span className="draw">8</span>
                                <div className="der9" >
                                    <h4>حكوومەتی عێراق بۆ نۆژەنكردنەوەی كارگەیەكی دیالە، 700 ملیۆن دۆلاری بە كۆمپانیایەكی خەیاڵی بەخشیوە        </h4>
                                </div>
                            </a>


                        </div>





                    </div>




                </div>

            </section>


            <div className="bapala" style={{ position: "fixed", bottom: "0px", width: "100%", zIndex: "1" }}>
                <div className="shrit">
                    <div id="secdetails">





                    </div>
                </div>

            </div>



            <section className='maf'>
                <div className="chewa zher">
                    <div className='rubar'>


                        <div className="zor">

                            <div className="xwar" style={{ width: "%25" }}>
                                <ul className="">
                                    <li className="sarbash"> هه‌واڵه‌كان </li>
                                    <li className="nett"><a href="/so/bash/115/0"> كوردستانی </a></li>
                                    <li className="nett"><a href="/so/bash/116/0"> عێراقی </a></li>
                                    <li className="nett"><a href="/so/bash/118/0"> تاراوگه‌ </a></li>
                                    <li className="nett"><a href="/so/bash/117/0"> ڕۆژهه‌ڵاتی ناوه‌ڕاست </a></li>
                                    <li className="nett"><a href="/so/bash/119/0"> جیهان </a></li>
                                </ul>
                            </div>


                            <div className="xwar" style={{ width: "%25" }}>
                                <ul className="">
                                    <li className="sarbash"> لایف ستایل  </li>
                                    <li className="nett"><a href="https://www.basnews.com"> ژینگه‌ </a></li>
                                    <li className="nett"><a href="https://www.basnews.com"> ته‌ندروستی </a></li>
                                    <li className="nett"><a href="https://www.basnews.com"> هه‌مه‌جۆر </a></li>
                                    <li className="nett"><a href="https://www.basnews.com"> ته‌كنه‌لۆژیا </a></li>
                                    <li className="nett"><a href="https://www.basnews.com"> گه‌شت و گوزار </a></li>
                                </ul>
                            </div>



                            <div className="xwar" style={{ width: "%25" }}>
                                <ul className="">
                                    <li className="sarbash">  كولتوور و هونه‌ر </li>
                                    <li className="nett"><a href="https://www.basnews.com"> چالاكی </a></li>
                                    <li className="nett"><a href="https://www.basnews.com"> په‌رتوك </a></li>
                                    <li className="nett"><a href="https://www.basnews.com"> سینه‌ما </a></li>
                                    <li className="nett"><a href="https://www.basnews.com"> میوزیك </a></li>
                                    <li className="nett"><a href="https://www.basnews.com"> شانۆ و دراما </a></li>
                                </ul>
                            </div>


                            <div className="xwar" style={{ width: "%25" }}>
                                <ul className="">
                                    <li className="sarbash"> زمانه‌كان </li>
                                    <li className="nett"><a href="/kr/"> كوردی </a></li>
                                    <li className="nett"><a href="/ar/"> عربی </a></li>
                                    <li className="nett"><a href="/fa/"> فارسی </a></li>
                                    <li className="nett"><a href="/tr/"> توركی </a></li>
                                    <li className="nett"><a href="/en/"> ئینگلیزی </a></li>

                                </ul>
                            </div>


                        </div>





                        <div className="kam">

                            <div className="spii">
                                <a href="/so/">
                                    <img src="https://www.basnews.com/images/logo.svg" style={{ filter: "grayscale(1)", width: "250px" }} />
                                </a>
                            </div>

                            <div className="">
                                <ul>
                                    <li>
                                        <a href="https://www.basnews.com/basnews.apk" style={{ cursor: "pointer" }}><i className="fa fa-android"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://itunes.apple.com/sb/app/basnews/id1135859153?mt=8" style={{ cursor: "pointer" }}><i className="fa fa-apple"></i></a>
                                    </li>

                                    <li>
                                        <a href="https://www.instagram.com/basnewss/" target="_blank"><i className="fa fa-instagram"></i></a>
                                    </li>

                                    <li>
                                        <a href="https://twitter.com/basnews2013" target="_blank"><i className="fa fa-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/basnews" target="_blank"><i className="fa fa-facebook"></i></a>
                                    </li>
                                </ul>
                            </div>


                            <h5 style={{ margin: "margin:20px 0px 20px 0px" }}> لـێــــره‌ ڕاستی بـزانــــــه ... Learn the Truth Here </h5>
                            <h5>Copyright ©2021 BasNews.com. All rights reserved</h5>

                        </div>




                    </div>
                </div>
            </section>




        </>
    )
}

export default Footer